/** @jsx jsx */
import { graphql } from 'gatsby';
import deepMerge from 'lodash/merge';
import { jsx } from 'theme-ui';
import ProductsPage from '../../gatsby-theme-event/components/ProductsPage/ProductsPage';

export default function ProductsTemplate({
  data,
  pageContext,
  location,
  navigate,
}) {
  const { event, site, products } = afterQuery(data);
  const { filterPriceRange } = event || {};

  return (
    <ProductsPage
      {...pageContext}
      // Make sure the page is remounted on a different href (i.e. a different query).
      key={location.href}
      event={event}
      products={products}
      siteTitle={site?.siteMetadata?.title}
      facetConfig={{
        filterPriceRange,
        ...pageContext,
      }}
      location={location}
      navigate={navigate}
    />
  );
}

function afterQuery({
  bottlebooks,
  event,
  firstProducts,
  products,
  winesFromGoogleSheet,
  site,
}) {
  const bb_event = bottlebooks?.event;
  const bb_products = bb_event?.registeredProducts?.nodes;

  const combinedProducts = products.nodes?.map((product, index) => {
    const refinedData = firstProducts?.nodes[index];
    const bb_registeredProduct = bb_products?.find(
      ({ productId }) => productId === product.productId
    );
    const wineFromGoogleSheet =
      winesFromGoogleSheet?.nodes?.find(
        ({ internalId }) => internalId === product.productId
      ) || {};
    return deepMerge(
      product,
      bb_registeredProduct?.product,
      // There is other data at this level, so we have to
      //explicitly pick each custom question
      { highlights: bb_registeredProduct?.highlights },
      refinedData,
      {
        ...wineFromGoogleSheet, // adds the group and potentially other customized fields from the google sheet.
        productNumber: String(wineFromGoogleSheet.productNumber || ''),
        productNumberSort: wineFromGoogleSheet.productNumber,
      }
    );
  });

  const sortedProducts = combinedProducts.sort((a, b) => {
    if (!a.productNumberSort) return 1;
    if (!b.productNumberSort) return -1;
    return a.productNumberSort > b.productNumberSort ? 1 : -1;
  });

  return {
    event,
    site,
    products: sortedProducts,
  };
}

export const pageQuery = graphql`
  query custom_ProductsTemplate(
    $eventId: ID!
    $locale: Bottlebooks_ContentLocale
    $filter: ProductFilterInput
    $sort: ProductSortInput
    $localeString: String
  ) {
    bottlebooks {
      event(eventId: $eventId, locale: $locale) {
        registeredProducts {
          nodes {
            productId
            product {
              ...bb_ProductSections
              ...bb_ProductFilters
              ...bb_ProductListRow
              ...bb_ProductRegion
              ...bb_ProductsPageBase
            }
          }
        }
      }
    }

    firstProducts: allProduct(filter: $filter, sort: $sort, limit: 10) {
      nodes {
        ...ProductsPage_first
      }
    }
    products: allProduct(filter: $filter, sort: $sort) {
      nodes {
        productId
        slug
        exhibitor {
          slug
        }
        ...ccc_ProductsPage
      }
    }
    event(locale: { eq: $localeString }) {
      ...ProductsPage_Event
      filterPriceRange
    }
    site {
      siteMetadata {
        title
      }
    }
    winesFromGoogleSheet: allGoogleWinesSheet {
      nodes {
        internalId
        ...cc_ProductNumber
      }
    }
  }
`;
