/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import { Box, Container } from '@bottlebooks/gatsby-theme-base';
import ProductFacetsBar from '@bottlebooks/gatsby-theme-event/src/components/Filters/Product/ProductFacetsBar';
import SearchHeader from '@bottlebooks/gatsby-theme-event/src/components/ProductsPage/SearchHeader';
import React from 'react';
import { jsx } from 'theme-ui';

export default function ProductsPageHeader({
  totalCount,
  count,
  state,
  dispatch,
}) {
  return (
    <React.Fragment>
      <Container fluid sx={{ maxWidth: 'container.fluid' }}>
        <SearchHeader
          totalCount={totalCount}
          count={count}
          sx={{ paddingTop: [4, 5], paddingBottom: [3, 4] }}
        />
      </Container>
      <Box sx={{ backgroundColor: 'light' }}>
        <Container fluid sx={{ maxWidth: 'container.fluid' }}>
          <ProductFacetsBar
            dispatch={dispatch}
            state={state}
            sx={{ marginBottom: 5 }}
          />
        </Container>
      </Box>
    </React.Fragment>
  );
}
