/** @jsx jsx */
import { gql } from '@apollo/client';
import { Box, Flex, Link, Text } from '@bottlebooks/gatsby-theme-base';
import {
  ProductHeaderFragment,
  ProductProducer,
} from '@bottlebooks/gatsby-theme-event/src/components/Product/ProductHeader';
import ProductImage, {
  ProductImageFragment,
} from '@bottlebooks/gatsby-theme-event/src/components/Product/ProductImage';
import ProductName from '@bottlebooks/gatsby-theme-event/src/components/Product/ProductName';
import useLink from '@bottlebooks/gatsby-theme-event/src/useLink';
// import { Trans } from '@lingui/macro';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
// import ProductComune from '../@bottlebooks/gatsby-theme-event/components/Product/ProductComune';
import ChiantiCheckbox from './ChiantiCheckbox';
import ProductNumber from './ProductNumber';
import ShowProductDetailsButton from './ShowProductDetailsButton';

export default function ProductListRow({
  product,
  cartIsEnabled,
  mayAddToCart,
  wasOrdered,
  isSelected,
  onToggleCart,
  ...rest
}) {
  const link = useLink();

  return (
    <Flex align="stretch" gap={2} {...rest}>
      {cartIsEnabled && (
        <Link
          sx={{
            padding: 2,
            display: 'flex',
            alignItems: 'center',
            minWidth: 40,
          }}
          onClick={mayAddToCart && onToggleCart}
        >
          <ChiantiCheckbox isChecked={isSelected} disabled={!mayAddToCart} />
        </Link>
      )}
      <Link
        align="top"
        onClick={cartIsEnabled && mayAddToCart && onToggleCart}
        to={!cartIsEnabled && link.product(product)}
        sx={{ flexGrow: 1, cursor: mayAddToCart && 'pointer' }}
      >
        <Flex align="center" gap={2}>
          <ProductNumber product={product} variant="small" />
          <ProductImage size="smaller" product={product} />
          <Box sx={{ flex: '1 1 0%' }}>
            <ProductProducer
              producer={product.producer}
              sx={{
                color: wasOrdered ? 'lightestText' : 'lightText',
              }}
            />
            <Text
              sx={{
                fontFamily: 'heading',
                fontWeight: 'heading',
                fontStyle: 'normal',
                color: cartIsEnabled
                  ? wasOrdered
                    ? 'lightText'
                    : 'inherit'
                  : 'primary',
                ':hover': { color: mayAddToCart && 'secondary' },
              }}
            >
              <ProductName {...product} />
            </Text>
          </Box>
        </Flex>
      </Link>
      {cartIsEnabled && <ShowProductDetailsButton product={product} />}
    </Flex>
  );
}

// HEADSUP: Product features have to be queried separately by the parent.

export const fragment = graphql`
  fragment bb_ProductListRow on Bottlebooks_Product {
    ...bb_ProductRequestSampleWithSnipcartButton
    ...bb_ProductHeader
  }
  fragment ccc_ProductListRow on Product {
    ...ProductImageSmaller_list
    ...ProductHeader
  }

  # A fragment for the first page of products. It has more detailed images.
  fragment ProductListRow_first on Product {
    ...ProductImage
  }
`;

ProductListRow.fragment = gql`
  fragment ProductListRow on Product {
    ...ProductImage
    ...ProductHeader
  }
  ${ProductImageFragment}
  ${ProductHeaderFragment}
`;
