/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import {
  Button,
  Col,
  DuplicateIcon,
  Flex,
  ListsIcon,
  Text,
} from '@bottlebooks/gatsby-theme-base';
import Notification from '@bottlebooks/gatsby-theme-event-tasting/src/components/Notification/Notification';
import useFavorites from '@bottlebooks/gatsby-theme-event-tasting/src/useFavorites';
import useUserFavorites from '@bottlebooks/gatsby-theme-event-tasting/src/useUserFavorites';
import useUserListsData from '@bottlebooks/gatsby-theme-event-tasting/src/useUserListsData';
import EmptyState from '@bottlebooks/gatsby-theme-event/src/components/ExhibitorsPage/EmptyState';
import ListRow from '@bottlebooks/gatsby-theme-event/src/components/List/ListRow';
import ProductListRow from '@bottlebooks/gatsby-theme-event/src/components/ProductsList/ProductListRow';
import ProductsList from '@bottlebooks/gatsby-theme-event/src/components/ProductsList/ProductsList';
import { Trans } from '@lingui/macro';
import React from 'react';
import { jsx } from 'theme-ui';
import createPersistedState from 'use-persisted-state';
import CCCProductListRow from '../../../../components/CCCProductListRow';
const rowVariantState = createPersistedState('bottlebooks:rowVariant');

const cartIsEnabled = !Boolean(process.env.GATSBY_SKIP_AUTH);
// The list of possible row variants we display.
// If not logged in, we only display the standard view.
// When logged in we display the compact view by default,
// with a toggle to switch to the standard view.
export const rowVariants = cartIsEnabled
  ? ['compact', 'standard']
  : ['standard', 'compact'];

export default function ProductsColumn({
  products,
  totalCount,
  visibleCount,
  onShowMore,
  ...rest
}) {
  /**
   * @typedef {object} Notification
   * @property {string} message
   * @property {boolean} [success]
   */
  /** @type {[Notification | undefined, React.Dispatch<React.SetStateAction<Notification | undefined>>]} */
  const [notification, setNotification] = React.useState();
  const [rowVariant, setRowVariant] = rowVariantState(rowVariants[0]);
  const { addToFavorites, removeFromFavorites } = useFavorites();
  const {
    canAddMoreProducts,
    itemsInCartCount,
    favorites: cart = [],
    setOptimisticFavorites: setOptimisticCart,
  } = useUserFavorites();
  const { userOrders } = useUserListsData();
  const orderedProducts = userOrders.flatMap(({ products }) => products);

  const visibleProducts = products.slice(0, visibleCount);
  const hasMore = products.length > visibleProducts.length;

  async function toggleProductForCart(productId) {
    if (cart.includes(productId)) {
      try {
        setOptimisticCart(cart.filter((id) => id !== productId));
        await removeFromFavorites(productId);
      } catch (err) {
        console.log(err);
        setNotification({ message: 'There was an error, please try again!' });
      }
    } else {
      if (!canAddMoreProducts) {
        return setNotification({
          message: `You can only place ${itemsInCartCount} products for each order!`,
        });
      }
      try {
        setOptimisticCart([...cart, productId]);
        await addToFavorites(productId);
      } catch (err) {
        console.log(err);
        setNotification({ message: 'There was an error, please try again!' });
      }
    }
  }

  return (
    <Col {...rest}>
      <VariantsBar
        value={rowVariant}
        onChange={setRowVariant}
        justify="flex-end"
        sx={{ marginBottom: 4 }}
      />
      {!totalCount ? (
        <EmptyState />
      ) : (
        <ProductsList sx={{ marginX: [-3, -2], marginBottom: -2 }}>
          {visibleProducts.map((product) =>
            rowVariant === 'standard' ? (
              <ProductListRow
                key={product.id}
                product={product}
                cartIsEnabled={cartIsEnabled}
                isSelected={cart?.includes(product.productId)}
                onToggleCart={
                  cartIsEnabled &&
                  (() => toggleProductForCart(product.productId))
                }
                mayAddToCart={cartIsEnabled}
                wasOrdered={orderedProducts.includes(product.productId)}
                sx={{ margin: [0, 2] }}
              />
            ) : (
              <CCCProductListRow
                key={product.id}
                product={product}
                cartIsEnabled={cartIsEnabled}
                isSelected={cart?.includes(product.productId)}
                onToggleCart={
                  cartIsEnabled &&
                  (() => toggleProductForCart(product.productId))
                }
                mayAddToCart={cartIsEnabled}
                wasOrdered={orderedProducts.includes(product.productId)}
                sx={{ margin: 2 }}
              />
            )
          )}

          {hasMore && (
            <Button
              as={ListRow}
              variant="empty"
              onClick={() => onShowMore()}
              sx={{
                margin: [0, 2],
                paddingX: 4,
                paddingY: 3,
                textAlign: 'center',
                display: 'block',
                border: 0,

                variant: 'text.small',
              }}
            >
              <Trans>Show more</Trans>
            </Button>
          )}
        </ProductsList>
      )}
      {notification && (
        <Notification
          onClose={() => setNotification(undefined)}
          message={notification.message}
          variant={notification.success ? 'success' : 'error'}
        />
      )}
    </Col>
  );
}
function VariantsBar({ value, onChange, ...rest }) {
  if (rowVariants.length < 2) return null;
  return (
    <Flex gap={2} align="center" {...rest}>
      <Text>
        <Trans>View:</Trans>
      </Text>
      {rowVariants.map((variant) => (
        <Button
          key={variant}
          onClick={() => onChange(variant)}
          variant={variant === value ? 'outline' : 'text'}
        >
          {variant === 'compact' && <ListsIcon />}
          {variant === 'standard' && <DuplicateIcon />}
        </Button>
      ))}
    </Flex>
  );
}
