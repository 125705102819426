/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import { Col } from '@bottlebooks/gatsby-theme-base';
import FiltersToggleButton from '@bottlebooks/gatsby-theme-event/src/components/Filters/FiltersToggleButton';
import { searchTermChanged } from '@bottlebooks/gatsby-theme-event/src/components/Filters/Product/productFilterReducer';
import ProductFilters from '@bottlebooks/gatsby-theme-event/src/components/Filters/Product/ProductFilters';
import ProductSearchTermFacet from '@bottlebooks/gatsby-theme-event/src/components/Filters/Product/ProductSearchTermFacet';
import React from 'react';
import { jsx } from 'theme-ui';

export default function ProductFiltersColumn({
  products,
  totalCount,
  visibleCount,
  resetVisibleCount,
  state,
  dispatch,
  facets,
  ...rest
}) {
  const [filtersHidden, setFiltersHidden] = React.useState(true);

  function dispatchFilter(event, isActive) {
    dispatch(event);
    resetVisibleCount();
    if (isActive) setFiltersHidden(true);
  }

  return (
    <Col {...rest}>
      <ProductSearchTermFacet
        value={state.searchTerm}
        onChange={(value) => dispatch(searchTermChanged(value))}
      />
      <FiltersToggleButton
        onToggle={() => setFiltersHidden(!filtersHidden)}
        filtersHidden={filtersHidden}
        sx={{
          display: ['flex', null, 'none'],
          marginTop: -3,
          marginBottom: 3,
        }}
      />
      <ProductFilters
        facets={facets}
        dispatch={dispatchFilter}
        state={state}
        totalCount={totalCount}
        count={products.length}
        sx={{
          display: [filtersHidden ? 'none' : null, null, 'block'],
        }}
      />
    </Col>
  );
}
