/** @jsx jsx */
import { gql } from '@apollo/client';
import { Box, Flex, Link, Text } from '@bottlebooks/gatsby-theme-base';
import ListRow from '@bottlebooks/gatsby-theme-event/src/components/List/ListRow';
import ListRowBody from '@bottlebooks/gatsby-theme-event/src/components/List/ListRowBody';
import ProductBadge from '@bottlebooks/gatsby-theme-event/src/components/Product/ProductBadge';
import ProductHeader, {
  ProductHeaderFragment,
} from '@bottlebooks/gatsby-theme-event/src/components/Product/ProductHeader';
import ProductImage, {
  ProductImageFragment,
} from '@bottlebooks/gatsby-theme-event/src/components/Product/ProductImage';
import useLink from '@bottlebooks/gatsby-theme-event/src/useLink';
import { Trans } from '@lingui/macro';
import { graphql } from 'gatsby';
import React from 'react';
import { jsx } from 'theme-ui';
import AddToCartButton from '../Product/ProductActions/AddToCartButton';

export default function ProductListRow({
  product,
  isSelected,
  cartIsEnabled,
  onToggleCart,
  mayAddToCart,
  wasOrdered,
  ...rest
}) {
  const link = useLink();
  return (
    <ListRow {...rest}>
      <Link
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          flexGrow: 1,
          color: cartIsEnabled
            ? wasOrdered
              ? 'lightText'
              : 'primary'
            : undefined,
        }}
        to={link.product(product)}
        {...rest}
      >
        <ProductListRowImage product={product} />
        <ListRowBody sx={{ position: 'relative' }}>
          <ProductHeader product={product} sx={{ color: 'muted' }} />
        </ListRowBody>
        <ProductFeatures product={product} />
      </Link>
      <Flex
        direction="row-reverse"
        sx={{
          marginRight: 2,
          marginBottom: 2,
          width: '100%',
        }}
      >
        {cartIsEnabled && (
          <React.Fragment>
            {mayAddToCart && <AddToCartButton productId={product.productId} />}
            {wasOrdered && (
              <Text>
                <Trans>You already ordered this wine.</Trans>
              </Text>
            )}
          </React.Fragment>
        )}
      </Flex>
    </ListRow>
  );
}

export function ProductListRowImage({ product }) {
  return (
    <Box sx={{ margin: 3, marginRight: 0 }}>
      <ProductImage product={product} />
    </Box>
  );
}

export function ProductFeatures({ product }) {
  if (!product.features?.length) return null;
  return (
    <Flex
      sx={{
        width: '100%',
        paddingRight: 2,
        paddingBottom: 2,
        alignSelf: 'flex-end',
        position: 'relative', // Needed to stay above image backgrounds
        marginLeft: 'auto',
        justifyContent: 'flex-end',
      }}
    >
      {product.features?.map((type) => (
        // Product features have to be queried separately by the parent.
        <ProductBadge
          key={type}
          variant="onPrimary"
          type={type}
          sx={{ marginTop: -3 }}
        />
      ))}
    </Flex>
  );
}

// HEADSUP: Product features have to be queried separately by the parent.

export const fragment = graphql`
  fragment bb_ProductListRow on Bottlebooks_Product {
    ...bb_ProductRequestSampleWithSnipcartButton
    ...bb_ProductHeader
  }
  fragment ProductListRow on Product {
    ...ProductImage_list
    ...ProductHeader
  }

  # A fragment for the first page of products. It has more detailed images.
  fragment ProductListRow_first on Product {
    ...ProductImage
  }
`;

ProductListRow.fragment = gql`
  fragment ProductListRow on Product {
    ...ProductImage
    ...ProductHeader
  }
  ${ProductImageFragment}
  ${ProductHeaderFragment}
`;
