/** @jsx jsx */
import { Container, Row } from '@bottlebooks/gatsby-theme-base';
import MobileShoppingCartWidget from '@bottlebooks/gatsby-theme-event-tasting/src/components/MobileShoppingCartWidget';
import createFacets from '@bottlebooks/gatsby-theme-event/src/components/Filters/Product/productFacets';
import reducer, {
  initialState,
} from '@bottlebooks/gatsby-theme-event/src/components/Filters/Product/productFilterReducer';
import useFilter from '@bottlebooks/gatsby-theme-event/src/components/Filters/useFilter';
import useSyncedLocation, {
  getStateFromLocation,
} from '@bottlebooks/gatsby-theme-event/src/components/Filters/useSyncedLocation';
import Layout from '@bottlebooks/gatsby-theme-event/src/components/Layout';
import useSearch from '@bottlebooks/gatsby-theme-event/src/components/ProductsPage/useProductSearch';
import SEO from '@bottlebooks/gatsby-theme-event/src/components/seo';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { graphql } from 'gatsby';
import React from 'react';
import { jsx } from 'theme-ui';
import ProductFiltersColumn from './ProductFiltersColumn';
import ProductsColumn from './ProductsColumn';
import ProductsPageHeader from './ProductsPageHeader';

const PAGE_SIZE = 30;

export default function ProductsPage({
  products: allProducts,
  location,
  facetConfig,
  locale,
}) {
  const stateFromLocation = getStateFromLocation(location, initialState);
  /** @type {[state: typeof initialState, dispatch: React.Dispatch<[event: string, payload?: any]>]} */
  const [state, dispatch] = React.useReducer(reducer, stateFromLocation);
  // Change the URL hash when the filters change.
  useSyncedLocation(state, { initialState, location });
  // Filter the products by term and facets.
  const searchedProducts = useSearch(allProducts, state.searchTerm);
  const { items, facets } = useFilter(searchedProducts, {
    state,
    facetDefinitions: createFacets(facetConfig, state),
    // sortOptions,
  });
  // Paginate the products.
  const [visibleCount, setVisibleCount] = React.useState(PAGE_SIZE);
  const { i18n } = useLingui();

  return (
    <Layout locale={locale}>
      <SEO page={{ path: location.href, title: i18n._(t`All Wines`) }} />
      <ProductsPageHeader
        totalCount={allProducts?.length}
        count={items?.length}
        state={state}
        dispatch={dispatch}
      />

      <Container
        fluid
        sx={{
          marginTop: 2,
          marginBottom: 6,
          maxWidth: 'container.fluid',
        }}
      >
        <Row>
          <ProductFiltersColumn
            products={items}
            totalCount={allProducts?.length}
            visibleCount={visibleCount}
            resetVisibleCount={() => setVisibleCount(PAGE_SIZE)}
            state={state}
            dispatch={dispatch}
            facets={facets}
            flex
          />
          <ProductsColumn
            products={items}
            totalCount={allProducts?.length}
            visibleCount={visibleCount}
            onShowMore={() => setVisibleCount(visibleCount + PAGE_SIZE)}
            sx={{ width: ['100%', null, `${(100 / 12) * 7}%`] }}
          />
        </Row>
      </Container>

      <MobileShoppingCartWidget sx={{ zIndex: 'raised' }} />
    </Layout>
  );
}

export const fragment = graphql`
  fragment bb_ProductsPageBase on Bottlebooks_Product {
    productId
    ...bb_ProductFilters
    ...bb_ProductListRow
    ...bb_Search_Product
    # ...useLink_bb_Product # TODO This doesn't work because exhibitorId isn't resolved correctly on RegisteredProduct.product.
  }

  fragment ccc_ProductsPage on Product {
    id
    features # The features have to be queried outside of the list row because they are not typically shown.
    ...ccc_ProductListRow
    ...Search_Product
    ...ProductFilters
    ...useLink_Product # TODO exhibitorId isn't available on RegisteredProduct.product, so we need to make sure it comes from Product at least.
  }

  fragment ProductsPage_first on Product {
    ...ProductListRow_first
    ...ProductListRow
  }

  fragment ProductsPage_Event on Event {
    ...EventBanner
  }
`;
