/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import { Box } from '@bottlebooks/gatsby-theme-base';
import { jsx } from 'theme-ui';

export default function ChiantiCheckbox({
  isChecked,
  disabled = false,
  ...rest
}) {
  return (
    <Box
      sx={{
        height: 24,
        width: 24,
        borderRadius: 'round',
        border: isChecked ? 'none' : 1,
        borderColor: disabled ? 'border' : 'borderNuanced',
        backgroundColor: isChecked ? 'primary' : '',
      }}
      {...rest}
    />
  );
}
