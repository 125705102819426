/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import { ChevronRightIcon, Link } from '@bottlebooks/gatsby-theme-base';
import useLink from '@bottlebooks/gatsby-theme-event/src/useLink';
import { jsx } from 'theme-ui';

export default function ShowProductDetailsButton({ product }) {
  const link = useLink();
  return (
    <Link
      to={link.product(product)}
      sx={{ color: 'accent', padding: 2, display: 'flex' }}
    >
      <ChevronRightIcon sx={{ marginY: 'auto' }} />
    </Link>
  );
}
