/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import { Box, Text } from '@bottlebooks/gatsby-theme-base';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';

export default function ProductNumber({
  product,
  variant = 'default',
  ...rest
}) {
  if (!product.productNumber) return null;
  if (variant === 'small')
    return (
      <Text sx={{ color: 'text', textAlign: 'right', width: 24 }} {...rest}>
        {product.productNumber}
      </Text>
    );
  return (
    <Box
      sx={{
        display: 'inline-block',
        color: 'onDark',
        backgroundColor: '#618e3c',
        borderColor: 'black',
        paddingX: 3,
        paddingY: 1,
        fontStyle: 'normal',
      }}
      {...rest}
    >
      <Text
        variant="larger"
        sx={{
          fontWeight: 600,
        }}
      >
        {product.productNumber}
      </Text>
    </Box>
  );
}

export const fragment = graphql`
  fragment cc_ProductNumber on googleWinesSheet {
    productNumber
  }
`;
